import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchProduct } from 'src/actions/product'
import {
  CCard,
  CCollapse,
  CFormInput,
  CFormLabel,
  CListGroup,
  CListGroupItem,
  CCol,
  CFormText,
} from '@coreui/react'
import PropTypes from 'prop-types'
import { FaCircleXmark } from 'react-icons/fa6'
import FallbackImage from 'src/components/FallbackImage'

const ProductDropDown = ({
  cols = 4,
  setPayload,
  valueName = 'product_id',
  title = 'Products',
  searchName = '',
  error,
  top = false,
}) => {
  const dispatch = useDispatch()
  const [isActive, setIsActive] = useState(false)
  const products = useSelector((state) => state.products)
  const auth = useSelector((state) => state.auth.token)
  const [result, setResult] = useState(products)
  const [search, setSearch] = useState('')

  const handleChangeInput = (searchKey) => {
    setSearch(searchKey)
  }

  const handleOnclick = (resultProduct) => {
    setPayload((prevInfo) => {
      return {
        ...prevInfo,
        [valueName]: resultProduct.id,
      }
    })
    setSearch(`${resultProduct.name}`)
    setIsActive(false)
  }

  const handleClear = () => {
    setSearch('')
    setIsActive(false)
  }

  useEffect(() => {
    if (products.length === 0) {
      dispatch(fetchProduct(auth))
    }
  }, [products, auth])

  useEffect(() => {
    if (search.length > 0) {
      setResult(() => {
        const filteredResult = products.filter((item) =>
          item.name.toLowerCase().includes(search.toLowerCase()),
        )
        return filteredResult
      })
      return
    }
    setPayload((prevInfo) => {
      return {
        ...prevInfo,
        [valueName]: '',
      }
    })
    setResult(products)
  }, [products, search])

  useEffect(() => {
    setSearch(searchName)
  }, [searchName])

  return (
    <CCol xs={12} md={cols}>
      <div className="mb-3">
        <CFormLabel>{title}</CFormLabel>
        <div className="position-relative content">
          {isActive && (
            <CCard
              className="position-absolute text-nowrap w-100"
              style={{
                maxHeight: '200px',
                overflowY: 'auto',
                zIndex: '10',
                ...(top ? { bottom: '37px' } : { top: '37px' }),
                minWidth: 'max-content',
              }}
            >
              <CCollapse visible={true}>
                <CListGroup>
                  {result?.map((result, index) => (
                    <CListGroupItem
                      key={index}
                      className="light-gray-hover d-flex"
                      onClick={() => handleOnclick(result)}
                    >
                      <div className="agent_avatar">
                        <FallbackImage
                          path={result?.thumbnail}
                          title={result?.name}
                          clickable={false}
                        />
                      </div>
                      <div
                        className="d-flex align-items-center ms-2"
                        style={{ width: '80%', fontSize: '14px' }}
                      >
                        {result?.name}
                      </div>
                    </CListGroupItem>
                  ))}
                </CListGroup>
              </CCollapse>
            </CCard>
          )}

          <CFormInput
            type="text"
            value={search}
            onChange={(e) => handleChangeInput(e.target.value)}
            placeholder={'Select Product'}
            onFocus={() => setIsActive(true)}
            onBlur={() =>
              setTimeout(() => {
                setIsActive(false)
              }, 400)
            }
          />
          <FaCircleXmark
            className=" position-absolute"
            style={{ right: '8px', top: '30%', cursor: 'pointer' }}
            onClick={handleClear}
          />
        </div>
        {error && (
          <CFormText id="thumbnail" className="text-danger">
            {error}
          </CFormText>
        )}
      </div>
    </CCol>
  )
}

ProductDropDown.propTypes = {
  cols: PropTypes.number,
  setPayload: PropTypes.func.isRequired,
  valueName: PropTypes.string,
  title: PropTypes.node,
  searchName: PropTypes.string,
  error: PropTypes.string,
  top: PropTypes.bool,
}

export default ProductDropDown
