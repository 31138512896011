import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import {
  CCard,
  CCardBody,
  CTable,
  CTableHead,
  CTableRow,
  CTableHeaderCell,
  CTableBody,
  CButton,
} from '@coreui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import API from '../../../../network/API'
import useAxios from '../../../../hooks/useAxios'
import { paginateIteration, emptyObject } from '../../../../utils/index'
import ProductTableRow from './ProductTableRow'
import ResultNotFound from '../../../ResultNotFound'
import Pagination from '../../../Pagination/Pagination'
import TotalCount from '../../../TotalCount/TotalCount'
import TableLoading from '../../../TableLoading'
import ProductFilter from './ProductFilter'

const ProductTable = () => {
  const { instance } = useAxios()
  const { id } = useParams()
  const [products, setProducts] = useState([])
  const [payload, setPayload] = useState({
    agent_id: id,
    name: '',
    sale_region: 'MM',
    type: '',
  })
  const [paginateIndex, setPaginateIndex] = useState(0)
  const [pagination, setPagination] = useState({ currentPage: 1, totalCount: 0, totalPage: 0 })
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const emptyPayload = emptyObject(payload)

  const fetchProducts = async (page = 1) => {
    if (!emptyObject(payload) || page) {
      setLoading(true)
    }
    const url = `${API.agentProducts}`
    const response = await instance
      .post(url, {
        ...payload,
        agent_id: id,
        name: payload.name,
        page: page ?? 1,
        row_count: 15,
        sale_region: payload.sale_region,
        type: payload.type,
      })
      .catch((error) => {
        const errors = error?.response.data
        const { code, message } = errors
        if (code === 400) {
          setError(message)
        }
      })

    if (response) {
      setLoading(false)
      const { data } = response

      setPagination({
        ...pagination,
        currentPage: data?.pagination.page,
        totalCount: data?.pagination.total_records_count,
        totalPage: data?.pagination.total_page,
      })

      setProducts([...data?.data])

      setPaginateIndex(paginateIteration(data?.data, data?.pagination.page, 15))
    }
  }
  useEffect(() => {
    fetchProducts()
  }, [payload])

  const handlePage = (page) => {
    setPagination({
      ...pagination,
      currentPage: page,
    })
    fetchProducts(page)
  }

  return (
    <>
      <CCard>
        <CCardBody>
          <div className="card_container">
            <Link to={`/agents/${id}/products/create`}>
              <CButton size="sm" className="card_container__button">
                <FontAwesomeIcon icon={faPlus} color="rgb(255, 255, 255)" /> New Product
              </CButton>
            </Link>
          </div>

          <ProductFilter payload={payload} setPayload={setPayload} />
          <>{pagination.totalCount > 0 && <TotalCount count={pagination.totalCount} />}</>

          <CTable hover responsive>
            <CTableHead>
              <CTableRow>
                <CTableHeaderCell scope="col" className="text-center">
                  #
                </CTableHeaderCell>
                <CTableHeaderCell scope="col" className="text-center">
                  Image
                </CTableHeaderCell>
                <CTableHeaderCell scope="col">Product Name</CTableHeaderCell>
                <CTableHeaderCell scope="col" className="text-center">
                  Region
                </CTableHeaderCell>
                <CTableHeaderCell scope="col" className="text-center">
                  Type
                </CTableHeaderCell>
                <CTableHeaderCell scope="col" className="text-end pr-1">
                  Total Stock
                </CTableHeaderCell>
                <CTableHeaderCell scope="col" className="text-end pr-1">
                  Good Stock
                </CTableHeaderCell>
                <CTableHeaderCell scope="col" className="text-end">
                  Damage Stock
                </CTableHeaderCell>
                <CTableHeaderCell scope="col" className="text-end">
                  Actions
                </CTableHeaderCell>
              </CTableRow>
            </CTableHead>
            <CTableBody>
              {!loading &&
                (products.length > 0 ? (
                  products.map((product, index) => (
                    <ProductTableRow
                      paginateIndex={paginateIndex}
                      index={index}
                      key={index}
                      product={product}
                      agentId={id}
                    />
                  ))
                ) : (
                  <ResultNotFound colspan={12} text="Products" show={emptyPayload} />
                ))}
            </CTableBody>
            {loading && <TableLoading colspan={12} />}
          </CTable>
          <nav className="col-12 d-flex justify-content-end mt-4">
            <Pagination
              totalCount={pagination.totalCount}
              pageSize={pagination.totalPage}
              currentPage={pagination.currentPage}
              onPageChange={(page) => handlePage(page)}
            />
          </nav>
        </CCardBody>
      </CCard>
    </>
  )
}

export default ProductTable
