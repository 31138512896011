import { SET_STOCKTYPE } from '../actions/types'

const intialState = []

const stockTypeReducer = (state = intialState, action) => {
  const { type, payload } = action

  switch (type) {
    case SET_STOCKTYPE:
      return payload
    default:
      return state
  }
}

export default stockTypeReducer
