import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { CCol, CFormLabel, CFormSelect } from '@coreui/react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchStockType } from '../../actions/stockType'

const StockTypeDropDown = ({ show = true, payload, setPayload, disabled, cols = 4 }) => {
  const dispatch = useDispatch()
  const types = useSelector((state) => state.types)
  const auth = useSelector((state) => state.auth.token)

  useEffect(() => {
    if (types.length === 0) {
      const fetchTyes = async () => {
        dispatch(fetchStockType(auth)).catch((error) => {
          const response = error.response?.data
        })
      }
      fetchTyes()
    }
  }, [types])

  return (
    <CCol xs={12} md={cols}>
      <div className="mb-3">
        <CFormLabel htmlFor="stock_status">Stock Type</CFormLabel>
        <CFormSelect
          disabled={disabled}
          aria-label="All"
          value={payload?.type ?? ''}
          onChange={(e) => setPayload({ ...payload, type: e.target.value })}
        >
          {show && <option value="">All</option>}
          {types?.map((type, index) => (
            <option key={index} value={type.id}>
              {type.name?.toUpperCase()}
            </option>
          ))}
        </CFormSelect>
      </div>
    </CCol>
  )
}

StockTypeDropDown.propTypes = {
  show: PropTypes.bool,
  payload: PropTypes.object.isRequired,
  setPayload: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  cols: PropTypes.string,
  errors: PropTypes.object,
  payloadKey: PropTypes.string,
}

export default StockTypeDropDown
