import { SET_LUCKYDRAW_PRIZE } from 'src/actions/types'

const initialState = []
const luckyDrawPrizeReducer = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case SET_LUCKYDRAW_PRIZE:
      return payload
    default:
      return state
  }
}

export default luckyDrawPrizeReducer
