const events = {
  luckyDrawEventList: '/admin/lucky-draw/event/list',
  luckyDrawEventDetail: '/admin/lucky-draw/event/detail',
  luckyDrawEventCreate: '/admin/lucky-draw/event/create',
  luckyDrawEventUpdate: '/admin/lucky-draw/event/update',
  luckyDrawEventDelete: '/admin/lucky-draw/event/delete',
  luckyDrawEvents: '/admin/lucky-draw/event',
  luckyDrawEventPeriodList: '/admin/lucky-draw/event/period/list',
  LuckyDrawEventPeriodDelete: 'admin/lucky-draw/event/period/delete',
}

export default events
