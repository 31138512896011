import React from 'react'
import PropTypes from 'prop-types'
import { CForm, CFormLabel, CFormInput, CRow, CCol } from '@coreui/react'
import 'bootstrap-daterangepicker/daterangepicker.css'
import RegionDropDown from '../../../SaleRegions/RegionDropDown'
import { debounce } from 'lodash'
import StockTypeDropDown from 'src/components/StockType/StockTypeDropDown'

const ProductFilter = ({ payload, setPayload }) => {
  const handleDebounce = (callbackFunc) => debounce(callbackFunc, 500)
  const handleChangeInput = (event) => {
    const { name, value } = event.target
    setPayload({
      ...payload,
      [name]: value,
    })
  }
  return (
    <>
      <CForm onSubmit={(e) => e.preventDefault()}>
        <CRow>
          <CCol xs={12} md={4}>
            <div className="mb-3">
              <CFormLabel htmlFor="status">Product</CFormLabel>
              <CFormInput
                type="text"
                id="name"
                name="name"
                aria-describedby="product"
                placeholder="Enter product name..."
                onChange={handleDebounce(handleChangeInput)}
              />
            </div>
          </CCol>
          <RegionDropDown payload={payload} setPayload={setPayload} />
          <StockTypeDropDown payload={payload} setPayload={setPayload} />
        </CRow>
      </CForm>
    </>
  )
}

ProductFilter.propTypes = {
  payload: PropTypes.object.isRequired,
  setPayload: PropTypes.func.isRequired,
}

export default ProductFilter
