import React from 'react'

const PrizeList = React.lazy(() => import('../pages/LuckyDraw/Prizes/PrizeList/PrizeList.jsx'))
const PrizeDetail = React.lazy(() =>
  import('../pages/LuckyDraw/Prizes/PrizeDetail/PrizeDetail.jsx'),
)
const PrizeCreate = React.lazy(() =>
  import('../pages/LuckyDraw/Prizes/PrizeCreate/PrizeCreate.jsx'),
)
const PrizeEdit = React.lazy(() => import('../pages/LuckyDraw/Prizes/PrizeEdit/PrizeEdit.jsx'))

export { PrizeList, PrizeDetail, PrizeCreate, PrizeEdit }
