import React from 'react'

const EventList = React.lazy(() => import('../pages/LuckyDraw/Events/EventList/EventList.jsx'))
const EventDetail = React.lazy(() =>
  import('../pages/LuckyDraw/Events/EventDetail/EventDetail.jsx'),
)
const EventCreate = React.lazy(() =>
  import('../pages/LuckyDraw/Events/EventCreate/EventCreate.jsx'),
)
const EventEdit = React.lazy(() => import('../pages/LuckyDraw/Events/EventEdit/EventEdit.jsx'))
export { EventList, EventCreate, EventDetail, EventEdit }
