const agentProducts = {
  agentProducts: '/admin/agents/products/list',
  agentSaleProducts: '/admin/agents/sale-products/list',
  agentProductDetail: '/agents/products/detail',
  createAgentProduct: '/agents/products/create',
  updateAgentProduct: '/agents/products/update',
  stockType: '/stocks/types-list',
}

export default agentProducts
