import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faEye } from '@fortawesome/free-solid-svg-icons'
import FallbackImage from '../../../FallbackImage'
import { CTableRow, CTableDataCell } from '@coreui/react'
import ProductInfo from 'src/components/Common/ProductInfo'
import { stockLabel } from 'src/utils'

const ProductTableRow = ({ paginateIndex, index, product, agentId }) => {
  return (
    <CTableRow>
      <CTableDataCell className="text-center"> {paginateIndex + index}</CTableDataCell>
      <CTableDataCell>
        <div className="image_path">
          <FallbackImage path={product?.thumbnail?.path} title={product?.name} />
        </div>
      </CTableDataCell>
      <CTableDataCell>
        <ProductInfo name={product?.name} tooltipText={`Product ID: ${product?.id}`} />
      </CTableDataCell>
      <CTableDataCell className="text-center">
        {product.sale_region === 'MM' ? 'Myanmar' : 'Thailand'}
      </CTableDataCell>

      <CTableDataCell className="text-center">
        <span className={`stock_type ${product.type?.name}`}>
          {product.type?.name.toUpperCase()}
        </span>
      </CTableDataCell>
      <CTableDataCell className="text-end">{product.total_stock}</CTableDataCell>
      <CTableDataCell className="text-end">{product.good_stock}</CTableDataCell>
      <CTableDataCell className="text-end">{product.damage_stock}</CTableDataCell>
      <CTableDataCell className="text-end">
        <Link to={`/agents/${agentId}/products/${product.agent_product_id}/edit`}>
          <span title="Edit Agent" className="mx-2">
            <FontAwesomeIcon icon={faEdit} color="rgb(33, 150, 243)" />
          </span>
        </Link>
        {/* <Link to={`/agents/${agentId}/products/${product?.id}/stock-histories`}>
          <span title="Stock Histories" className="mx-2">
            <FontAwesomeIcon icon={faEye} color="rgb(0, 200, 83)" />
          </span>
        </Link> */}
      </CTableDataCell>
    </CTableRow>
  )
}

ProductTableRow.propTypes = {
  paginateIndex: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  product: PropTypes.object.isRequired,
  agentId: PropTypes.string.isRequired,
}

export default ProductTableRow
