import { API_HOST } from '../network/domain'
import API from '../network/API'
import { SET_STOCKTYPE } from './types'
import axios from 'axios'

const url = `${API_HOST}/api${API.stockType}`

export const fetchStockType = (token) => async (dispatch) => {
  return axios
    .post(
      url,
      { row_count: 15, page: 1 },
      { headers: { Authorization: `Bearer ${token}`, 'device-Type': 'web' } },
    )
    .then((response) => {
      const { data } = response
      dispatch({
        type: SET_STOCKTYPE,
        payload: data?.data,
      })
      return Promise.resolve(response)
    })
    .catch((error) => {
      return Promise.reject(error)
    })
}
