const promotions = {
  allPromotions: '/admin/products/promotions/list',
  promotions: '/products/promotions/list',
  promotionDetail: '/products/promotions/detail',
  createPromotion: '/products/promotions/create',
  updatePromotion: '/products/promotions/update',
  deletePromotion: '/products/promotions/delete',
  monthlyPromotions: '/admin/monthly-promotion-group/list',
  monthlyPromotionDetail: 'admin/monthly-promotion-group/detail',
  createMonthlyPromotion: 'admin/monthly-promotion-group/create',
  updateMonthlyPromotion: 'admin/monthly-promotion-group/update',
  deleteMonthlyPromotions: 'admin/monthly-promotion-group/delete',
  focPromotions: '/foc-promotions/list',
  focPromotionDetail: '/foc-promotions/detail',
  createFocPromotion: '/foc-promotions/create',
  updateFocPromotion: '/foc-promotions/update',
  deleteFocPromotions: '/foc-promotions/delete',
  discountPromotions: '/discount-promotions/list',
  discountPromotionDetail: '/discount-promotions/detail',
  createDiscountPromotion: '/discount-promotions/create',
  updateDiscountPromotion: '/discount-promotions/update',
  deleteDiscountPromotions: '/discount-promotions/delete',
  doublePromotions: '/double-promotions/list',
  doublePromotionDetail: '/double-promotions/detail',
  createDoublePromotion: '/double-promotions/create',
  updateDoublePromotion: '/double-promotions/update',
  deleteDoublePromotion: '/double-promotions/delete',
  promotionGroupList: '/promotion-groups/list',
  promotionGroupDetail: '/promotion-groups/detail',
  createPromotionGroup: '/promotion-groups/create',
  editPromotionGroup: '/promotion-groups/update',
  deletePromotionGroup: '/promotion-groups/delete',
  allPromotionGroups: '/promotion-groups/all',
}

export default promotions
