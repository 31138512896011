import moment from 'moment'
import API from 'src/network/API'
import { API_HOST } from 'src/network/domain'

/**
 * Get the number of the pagination index in the slice.
 *
 * @returns {number}
 */
export const paginateIteration = (items, currentPage, perPage) => {
  return items?.length > 0 ? (currentPage - 1) * perPage + 1 : 0
}

/**
 * Check the value is object
 *
 * @returns {boolean}
 */
export const isObject = (value) => {
  return value instanceof Object
}

/**
 * String limit
 *
 * @returns {string}
 */
export const strLimit = (string, length) => {
  return string.length > length ? string.substring(0, length) + '...' : string
}

/**
 * Agent status label color
 *
 * @returns {string}
 */
export const orderLabel = (status) => {
  switch (status) {
    case 'Pending':
      return 'agent_pending'
    case 'Register':
      return 'agent_register'
    case 'Active':
      return 'agent_active'
    case 'Inactive':
      return 'agent_inactive'
    case 'Deactivated':
      return 'agent_deactivated'
    case 'Skip':
      return 'agent_skip'
    default:
      return 'agent_pending'
  }
}

/**
 * Agent stock type  label color
 *
 * @returns {string}
 */
export const stockLabel = (status) => {
  switch (status) {
    case 1:
      return 'agent_pending'
    case 2:
      return 'agent_register'
    case 3:
      return 'agent_active'
    case 4:
      return 'agent_inactive'
    default:
      return 'agent_pending'
  }
}

/**
 * Transaction sale type label color
 *
 * @returns  {string}
 */
export const saleTypeLabel = (status) => {
  switch (status) {
    case 'PARTNER':
      return 'sale_type_partner'
    default:
      return 'sale_type_consumer'
  }
}

/**
 * Capitalize first letter string
 *
 * @returns {string}
 */
export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

/**
 * Check empty object value
 *
 * @returns {bool}
 */
export const emptyObject = (payload) => {
  return Object.values(payload).every((x) => x === null || x === '')
}

/**
 * Clone object
 *
 * @returns {object}
 */
export const omit = (revmoveKey, payload) => {
  return Object.keys(payload).reduce((object, key) => {
    if (key !== revmoveKey) {
      object[key] = payload[key]
    }
    return object
  }, {})
}

/**
 * Formatting double amount
 * @param {*} cents
 * @returns {double}
 */
export const formatPrice = (cents) => {
  return (cents / 100).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  })
}

/**
 * Number with comman
 * @returns {string}
 */
export const numberWithCommas = (payload) => {
  return payload?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

/**
 * Date format
 * @returns {string}
 */
export const dateFormat = (payload) => {
  return moment(payload).format('YYYY-MM-DD')
}

/**
 * Deplay debounce
 * @param {func, timer}
 * @returns {func}
 */
export const debounce = (func, milliseconds = 2000) => {
  let timer
  return function (...args) {
    const context = this
    if (timer) clearTimeout(timer)
    timer = setTimeout(() => {
      timer = null
      func.apply(context, args)
    }, milliseconds)
  }
}

/**
 * Current date (from date & to date)
 * @returns {object}
 */
export const currentDate = (func, milliseconds = 2000) => {
  const currentDate = moment().format('YYYY-MM-DD')

  return {
    fromDate: currentDate,
    toDate: currentDate,
  }
}

/**
 * Check upload file type
 * @returns {boolean}
 */

export const checkFileType = (fileName, acceptTypeList = ['xlsx', 'xls', 'csv', 'pdf']) => {
  return acceptTypeList.includes(fileName.split('.').pop().toLowerCase())
}

/**
 * Get Two digits if the number is under 10
 * @param  {number}
 * @returns
 */
export const getTwoDigits = (number) => {
  return ('0' + number).slice(-2)
}

/**
 * Convert public image url to base64
 * @param {string} imgUrl
 * @returns {string}
 */
export const getBase64FromUrl = async (imgUrl) => {
  const data = await fetch(imgUrl)
  const blob = await data.blob()
  return new Promise((resolve) => {
    const reader = new FileReader()
    reader.readAsDataURL(blob)
    reader.onloadend = () => {
      const base64data = reader.result
      resolve(data?.status === 200 ? base64data : null)
    }
  })
}

/**
 * Convert blob to file type
 * @param {obj} blob
 * @param {string} fileName
 * @returns {file}
 */
export const convertBlobToBase64 = async (image, fileName, fileType) => {
  const data = await fetch(image)
  const blob = await data.blob()
  const file = new File([blob], fileName, {
    type: fileType,
    lastModified: new Date(),
  })

  return file
}

/*
 * Convert nested array to set
 * @param {array} arrayList
 * @returns {Set}
 */
export const getSetData = (arrayList) => {
  const set1 = new Set()
  for (let i = 0; i < arrayList?.length; i++) {
    !set1?.has(arrayList[i]) ? set1.add(arrayList[i]) : set1.delete(arrayList[i])
  }

  return set1
}

export const getBonusDate = () => {
  return moment().subtract(1, 'months').startOf('months').format('YYYY-MM-DD')
}

export const enableBonusGenerate = (month) => {
  var enableGenerate = true
  const currentMonth = moment().month()
  const prevMonth = currentMonth - 1 >= 1 ? currentMonth - 1 : 12
  console.log('currentMonth::', currentMonth, 'passed month:', month)
  if (+month === currentMonth || +month === prevMonth) {
    enableGenerate = false
  }
  return enableGenerate
}

/*
 * Sum of key values number in a array
 * @param {array} arrayList
 * @returns {number}
 */
export const sumOfKeyValues = (list, key) => {
  return list.map((item) => item[key]).reduce((prev, next) => prev + next)
}

/*
 * Get generate certificate upload url
 * @param {string} generateType
 * @returns {string}
 */
export const getUploadUrl = (generateType) => {
  switch (generateType) {
    case 'all':
      return `${API_HOST}/api${API.uploadAllFrame}`
    case 'detail':
      return `${API_HOST}/api${API.uploadProductFrame}`
    case 'trip_product':
      return `${API_HOST}/api${API.tripProductUpload}`
    case 'six_months_bonus':
      return `${API_HOST}/api${API.uploadSixMonthsBonus}`
    case 'six_months_trip_all':
      return `${API_HOST}/api${API.uploadSixMonthTrip}`
    case 'year_end_trip_group':
      return `${API_HOST}/api${API.uploadYearEndTripGroup}`
    case 'year_end_award_group':
      return `${API_HOST}/api${API.uploadYearEndAwardGroup}`
    case 'birthday':
      return `${API_HOST}/api${API.uploadBirthday}`
    default:
      return ''
  }
}

/*
 * Get certificate download url
 * @param {string} generateType
 * @returns {string}
 */
export const getDownloadCertificateUrl = (generateType) => {
  switch (generateType) {
    case 'all':
      return `${API_HOST}/api${API.downloadAllFrame}`
    case 'detail':
      return `${API_HOST}/api${API.downloadProductFrame}`
    case 'trip_product':
      return `${API_HOST}/api${API.tripProductDownload}`
    case 'six_months_bonus':
      return `${API_HOST}/api${API.downloadSixMonthsBonus}`
    case 'six_months_trip_all':
      return `${API_HOST}/api${API.downloadSixMonthTripImage}`
    case 'year_end_trip_group':
      return `${API_HOST}/api${API.downloadYearEndTripGroup}`
    case 'year_end_award_group':
      return `${API_HOST}/api${API.downloadYearEndAwardGroup}`
    case 'birthday':
      return `${API_HOST}/api${API.downloadBirthday}`
    default:
      return ''
  }
}

/*
 * Get region name
 * @param {string} name
 * @returns {string}
 */
export const localization = (name) => {
  return name === 'MM' ? 'Myanmar' : name === 'TH' ? 'Thailand' : 'Global'
}

/*
 * Get region name
 * @param {id} id
 * @returns {string}
 */
export const localeName = (id) => {
  return id === 1 ? 'Myanmar' : id === 0 ? 'Global' : 'Thailand'
}

/*
 * Get region name
 * @param {id} id
 * @returns {string}
 */
export const localeShortName = (id) => {
  return id === 1 ? 'MM' : id === 0 ? 'GL' : 'TH'
}

/*
 * Get duplicate items
 * @param {array, array} arr1, arr2
 * @returns {array}
 */
export const getDuplicateItems = (arr1, arr2) => {
  const Set1 = new Set(arr2.map(({ id }) => id))
  return arr1.filter(({ id }) => Set1.has(id))
}

export const getGenerateSuccessMessage = (type) => {
  switch (type) {
    case 'all':
      return 'Monthly All Certificate Generated Successfully!'
    case 'detail':
      return 'Monthly Product Certficate Generated Successfully!'
    case 'trip_product':
      return 'Trip Product Group Certificate Generated Successfully'
    case 'six_months_bonus':
      return 'Six Months Bonus Certificate Generated Successfully'
    case 'six_months_trip_all':
      return 'Six Months Trip Certificate Generated Successfully'
    case 'year_end_trip_group':
      return 'Year End Trip Certificate Generated Successfully!'
    case 'year_end_award_group':
      return 'Year End Award Certificate Generated Successfully!'
    case 'birthday':
      return 'Birthday Card Generated Successfully!'
    default:
      return
  }
}

export const getGenerateTitle = (type) => {
  switch (type) {
    case 'birthday':
      return 'Generate Birthday Card'
    default:
      return 'Generate Certificate'
  }
}

/*
 * Get string concatination
 * @param {array, object} arr1, object
 * @returns {string}
 */
export const stringConcat = (array, object) => {
  let name = ''
  array?.forEach((key, index) => {
    if (index === array?.length - 1) {
      name += `${object[key]}`
      return
    }
    name += `${object[key]}_`
  })

  return name
}

export function getYearEndDateRange() {
  const currentDate = moment(new Date()).format('M-YYYY')
  const [month, year] = currentDate.split('-')
  let currentYear = Number(year)
  if (month === '1' || month === '2') {
    return [`${currentYear - 1}-03-01`, `${currentYear}-02-01`]
  }
  return [`${currentYear}-03-01`, `${currentYear + 1}-02-01`]
}

/*
 * Fetch Image Url
 * @param url
 * @returns {string}
 */
export function fetchImage(url) {
  return fetch(url)
    .then(async (response) => {
      const file = await response?.blob()
      return file
    })
    .catch((error) => {
      return error
    })
}

/**
 * Convert file to base64
 * @param {string} imgUrl
 * @returns {string}
 */
export function getBase64FromFile(blob) {
  return new Promise((resolve) => {
    const reader = new FileReader()
    reader.readAsDataURL(blob)
    reader.onloadend = () => {
      const base64data = reader.result
      resolve(base64data)
    }
  })
}

/**
 * Get image dimensions
 * @param {string} imgUrl
 * @returns {object}
 */
export function getImageDimensions(src) {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.src = src
    img.onload = () => {
      resolve({ width: img.naturalWidth, height: img.naturalHeight })
    }
    img.onerror = (error) => {
      reject(error)
    }
  })
}
